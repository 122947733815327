import React from "react"
import {graphql} from "gatsby"
import {injectIntl, Link, useIntl} from "gatsby-plugin-react-intl"
import Parser from "html-react-parser"

import Layout from "../components/layout"
import {FormattedMessage} from "react-intl";

const PublicationsPage = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale
    var publications = ""
    if (lang === "ru") {
        publications = data.ru_publications.edges;
    } else if (lang === "en") {
        publications = data.en_publications.edges;
    }
    return (
        <Layout>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{margin: 0}}>
                    <li className="breadcrumb-item">
                        <Link to="/about"><FormattedMessage id="header.group"/></Link>
                    </li>
                    <li className="breadcrumb-item active"><FormattedMessage id="about.pubs.title"/></li>
                </ol>
            </nav>
            <h1><FormattedMessage id="about.pubs.title"/></h1>
            <ul>
                {publications.map(({node}) => {
                    let ref = "#" + node.frontmatter.year
                    return (
                        <li><a href={ref}>{node.frontmatter.year}</a></li>
                    )
                })}
            </ul>
            <hr/>
            {publications.map(({node}) => {
                return (
                    <>
                        <h1 id={node.frontmatter.year}>{node.frontmatter.year}</h1>
                        <hr/>
                        <div>{Parser(node.html)}</div>
                        <hr/>
                    </>
                )
            })}

        </Layout>
    )
}

export default injectIntl(PublicationsPage)

export const query = graphql`
    query{
        ru_publications: allMarkdownRemark(
            filter: {frontmatter: {content_type: {eq: "publications"}, published: {ne: false}, language: {eq: "ru"}}},
            sort: { fields: [frontmatter___year], order: DESC }
        ){
            edges{
                node{
                    html
                    frontmatter{
                        language
                        year
                    }
                }
            }}

        en_publications: allMarkdownRemark(
            filter: {frontmatter: {content_type: {eq: "publications"}, published: {ne: false}, language: {eq: "en"}}},
            sort: { fields: [frontmatter___year], order: DESC }
        ){
            edges{
                node{
                    html
                    frontmatter{
                        language
                        year
                    }
                }
            }}
    }
`
